'use client'

import Script from 'next/script'

const FreshDeskScript = ({
	widgetId,
	authToken,
}: {
	widgetId: string
	authToken: string
}) => {
	return (
		<>
			<Script
				id="freshdesk"
				dangerouslySetInnerHTML={{
					__html: `
						window.fwSettings={
							'widget_id':${widgetId}
						};
						!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
						FreshworksWidget('authenticate', {
						  token: '${authToken}',
						});
					`,
				}}
			/>
			<Script
				type="text/javascript"
				src={`https://widget.freshworks.com/widgets/${widgetId}.js`}
				async
				defer
			/>
		</>
	)
}
export default FreshDeskScript
