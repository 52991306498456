'use client'

// eslint-disable-next-line etc/no-commented-out-code
// import * as Sentry from '@sentry/nextjs'
// import { useAuth, useUser } from '@clerk/nextjs'
// import { useEffect } from 'react'

const ObservabilityAuth = () => {
	// eslint-disable-next-line etc/no-commented-out-code
	// TODO replace this with datadog user tracking
	// const {
	// 	userId,
	// 	orgId,
	// } = useAuth()
	//
	// const {
	// 	user,
	// } = useUser()
	//
	// // Sentry
	// useEffect(() => {
	// 	if (userId && orgId) {
	// 		Sentry.setUser({
	// 			id: userId,
	// 			username: user?.username || user && `${user.firstName} ${user.lastName}` || undefined,
	// 			email: user?.emailAddresses[0].emailAddress,
	// 			organizationId: orgId,
	// 		})
	// 	} else if (userId) {
	// 		Sentry.setUser({
	// 			id: userId,
	// 			username: user?.username || user && `${user.firstName} ${user.lastName}` || undefined,
	// 			email: user?.emailAddresses[0].emailAddress,
	// 		})
	// 	} else {
	// 		Sentry.setUser(null)
	// 	}
	// }, [
	// 	user,
	// 	userId,
	// 	orgId,
	// 	user?.emailAddresses,
	// ])

	return null
}

export default ObservabilityAuth
