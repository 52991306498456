function numberOrDefault(value: string, defaultValue = 0): number {
	const n = Number.parseInt(value)
	if (Number.isNaN(n)) {
		return defaultValue
	}

	return n
}

export const publicConfig = {
	app: {
		host: process.env.NEXT_PUBLIC_APP_HOST as string,
		gitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string || 'development',
		isDev: process.env.NODE_ENV as string === 'development',
		developer: process.env.NEXT_PUBLIC_YOUR_NAME as string,
	},
	freshdesk: {
		widgetId: process.env.NEXT_PUBLIC_FRESHDESK_WIDGET_ID as string,
	},
	intercom: {
		appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string,
		plan: process.env.NEXT_PUBLIC_INTERCOM_PLAN as string || 'dev',
	},
	tailwind: {
		isIndicatorEnabled: process.env.NEXT_PUBLIC_ENABLE_TAILWIND_INDICATOR as string === 'true',
	},
	posthog: {
		key: process.env.NEXT_PUBLIC_POSTHOG_KEY as string,
		environment: process.env.NEXT_PUBLIC_POSTHOG_ENVIRONMENT as string,
	},
	datadog: {
		clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
		applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
		enableProxy: process.env.NEXT_PUBLIC_DATADOG_ENABLE_PROXY as string === 'true',
	},
	stripe: {
		publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
	},
}

export const serverConfig = {
	app: {
		host: process.env.APP_HOST as string,
		shortHost: process.env.APP_SHORT_HOST as string,
	},
	db: {
		url: process.env.DATABASE_URL as string,
		edgeUrl: process.env.EDGE_DATABASE_URL as string,
		prismaLogLevels: (process.env.PRISMA_LOG_LEVELS as string || '').split(',')
			.filter(Boolean).map((level) => {
				return level.trim()
			}),
	},
	kirby: {
		host: process.env.KIRBY_API_HOST as string,
		secret: process.env.KIRBY_API_SECRET as string,
	},
	jwt: {
		secret: process.env.JWT_SECRET_KEY as string,
	},
	twilio: {
		accountSid: process.env.TWILIO_ACCOUNT_SID,
		authToken: process.env.TWILIO_AUTH_TOKEN,
		phoneNumber: process.env.TWILIO_PHONE_NUMBER,
		verificationServiceId: process.env.TWILIO_VERIFICATION_SERVICE_ID,
	},
	sendGrid: {
		apiKey: process.env.SENDGRID_API_KEY,
		fromEmail: process.env.SENDGRID_FROM_EMAIL,
		enableClickTracking: process.env.SENDGRID_ENABLE_CLICK_TRACKING as string === 'true',
	},
	aws: {
		s3: {
			host: process.env.AWS_S3_HOST as string,
			bucket: process.env.AWS_BUCKET as string,
		},
	},
	mongo: {
		uri: process.env.MONGODB_DATABASE_URL as string,
		db: process.env.MONGODB_DB as string,
	},
	freshdesk: {
		secretKey: process.env.FRESHDESK_SECRET_KEY as string,
	},
	intercom: {
		secretKey: process.env.INTERCOM_SECRET_KEY as string,
	},
	mistralAi: {
		apiKey: process.env.MISTRAL_API_KEY as string,
	},
	openai: {
		secret: process.env.OPENAI_API_SECRET as string,
	},
	clerk: {
		secret: process.env.CLERK_WEBHOOK_SIGNING_SECRET as string,
	},
	dataImport: {
		dataImportClerkUserId: 'DATA_IMPORT_CLERK_USER_ID',
	},
	bridgitBench: {
		baseUrl: process.env.BRIDGIT_BASE_URL as string || 'https://bench.gobridgit.com',
		credentials: process.env.BRIDGIT_CREDENTIALS as string || '',
	},
	stripe: {
		secretKey: process.env.STRIPE_SECRET_KEY as string,
		webhookSecret: process.env.STRIPE_WEBHOOK_SECRET as string,
		noCodePortalURL: process.env.STRIPE_NO_CODE_PORTAL_URL as string,

		rateLimit: {
			readsPerSec: numberOrDefault(process.env.STRIPE_LIMIT_READS_PER_SEC as string || '', 100),
			writesPerSec: numberOrDefault(process.env.STRIPE_LIMIT_WRITES_PER_SEC as string || '', 100),
		},
	},
	prequal: {
		submissionFeeStripePriceId: process.env.PREQUAL_FEE_STRIPE_PRICE_ID as string,
	},
	ocrolus: {
		token: process.env.OCROLUS_TOKEN as string || '',
		baseURL: process.env.OCROLUS_BASE_URL as string || 'https://api.ocrolus.com',
		authBaseURL: process.env.OCROLUS_AUTH_BASE_URL as string || 'https://auth.ocrolus.com',
		clientId: process.env.OCROLUS_CLIENT_ID as string || '',
		clientSecret: process.env.OCROLUS_CLIENT_SECRET as string || '',
		isCacheEncryptionEnabled: process.env.OCROLUS_CACHE_ENABLE_ENCRYPTION === 'true',
		cacheEncryptionKey: process.env.OCROLUS_CACHE_ENC_KEY_HEX_STR as string || '',
		webhookUsername: process.env.OCROLUS_WEBHOOK_USERNAME as string || '',
		webhookPassword: process.env.OCROLUS_WEBHOOK_PASSWORD as string || '',
	},
	trustLayer: {
		baseURL: process.env.TRUST_LAYER_BASEURL as string || 'https://api.trustlayer.io',
		token: process.env.TRUST_LAYER_TOKEN as string || '',
	},
	airparser: {
		baseURL: process.env.AIRPARSER_BASEURL as string || 'https://api.airparser.com',
		apiKey: process.env.AIRPARSER_APIKEY as string || '',
		inboxId: {
			EMR: process.env.AIRPARSER_EMR_INBOX_ID as string || '',
			OSHA300A: process.env.AIRPARSER_OSHA300A_INBOX_ID as string || '',
		},
	},
}

export const staticConfig = {}
