'use client'

import type { ReactNode } from 'react'

import { createContext, useContext } from 'react'

import { client } from '~/app/_trpc/client'

type OrgPermissionsContextType = {
	orgPermissions: PrismaJson.OrgMetaPermissions
}

export const OrgPermissionsContext = createContext<OrgPermissionsContextType | undefined>(undefined)

export const useOrgPermissions = () => {
	const context = useContext(OrgPermissionsContext)
	if (context === undefined) {
		throw new Error('useOrgPermissions must be used within an OrgPermissionsProvider')
	}

	return context
}

export const OrgPermissionsProvider = ({
	children,
}: { children: ReactNode }) => {
	const {
		data,
		isLoading,
	} = client.organizations.getOrgPermissions.useQuery()

	return (
		<OrgPermissionsContext.Provider value={{
			orgPermissions: !isLoading && data ? data : {},
		}}
		>
			{children}
		</OrgPermissionsContext.Provider>
	)
}
