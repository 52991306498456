'use client'

import type { BootstrapConfig } from 'posthog-js'

import { datadogLogs } from '@datadog/browser-logs'
import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useUser, useOrganization } from '@clerk/nextjs'
import { getCookie } from 'cookies-next'

import { publicConfig } from '~/config'

const {
	applicationId,
	clientToken,
	enableProxy,
} = publicConfig.datadog

const {
	isDev,
	gitCommitSha,
} = publicConfig.app

const flags = getCookie('bootstrapData')

const {
	featureFlags,
} = (flags ? JSON.parse(flags.toString()) : {}) as BootstrapConfig

const useDatadogFeatureFlags = () => {
	useEffect(() => {
		datadogRum.init({
			applicationId,
			clientToken,
			site: 'datadoghq.com',
			proxy: enableProxy ? (options) => {
				return `${publicConfig.app.host}/_datadog${options.path}?${options.parameters}`
			} : undefined,
			service: 'trestle',
			env: isDev ? 'development' : 'production',
			version: gitCommitSha,
			sessionSampleRate: isDev ? 20 : 100,
			sessionReplaySampleRate: isDev ? 0 : 20,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'mask-user-input',
			telemetryConfigurationSampleRate: isDev ? 20 : 100,
			traceSampleRate: isDev ? 20 : 100,
			telemetrySampleRate: isDev ? 20 : 100,
			telemetryUsageSampleRate: isDev ? 20 : 100,
			allowedTracingUrls: [
				{
					match: 'https://app.gotrestle.com',
					propagatorTypes: [
						'tracecontext',
					],
				},
				{
					match: 'https://gotrestle.com',
					propagatorTypes: [
						'tracecontext',
					],
				},
			],
			enableExperimentalFeatures: [
				'feature_flags',
			],
		})

		if (isDev) {
			datadogRum.setGlobalContextProperty('developer', publicConfig.app.developer || 'unknown')
		}

		if (featureFlags) {
			for (const [
				featureFlag,
				value,
			] of Object.entries(featureFlags)) {
				datadogRum.addFeatureFlagEvaluation(featureFlag, value)
			}
		}
	}, [])

	const {
		user,
	} = useUser()

	const {
		organization,
	} = useOrganization()

	useEffect(() => {
		if (user) {
			datadogRum.setUser({
				id: user.id,
				name: `${user.firstName} ${user.lastName}`,
				email: user.emailAddresses[0].emailAddress,
				...organization ? {
					organizationId: organization.id,
					organizationName: organization.name,
				} : {},
			})
		}
	})
}

const Datadog = () => {
	useDatadogFeatureFlags()

	datadogLogs.init({
		clientToken: publicConfig.datadog.clientToken,
		site: 'datadoghq.com',
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
	})

	return null
}

export default Datadog
