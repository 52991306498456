'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState, type ReactNode } from 'react'
import superjson from 'superjson'
import { httpLink } from '@trpc/client'

import { client } from './client'

const getBaseUrl = () => {
	if (typeof window !== 'undefined') {
		return ''
	}

	const vc = process.env.VERCEL_URL
	if (vc) {
		return `https://${vc}`
	}

	return 'http://localhost:3000'
}

const TRPCProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	const [
		queryClient,
	] = useState(() => {
		return new QueryClient({})
	})
	const [
		trpcClient,
	] = useState(() => {
		return client.createClient({
			transformer: superjson,
			links: [
				httpLink({
					url: `${getBaseUrl()}/api/trpc`,
					fetch,
				}),
			],
		})
	})

	return (
		<client.Provider
			queryClient={queryClient}
			client={trpcClient}
		>
			<QueryClientProvider client={queryClient}>
				{children}
			</QueryClientProvider>
		</client.Provider>
	)
}

export default TRPCProvider
