'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { usePostHog } from 'posthog-js/react'
import { useOrganization, useUser } from '@clerk/nextjs'

import { publicConfig } from '~/config'

const PostHogPageView = () => {
	const pathname = usePathname()
	const searchParams = useSearchParams()
	const posthog = usePostHog()

	// Track page views
	useEffect(() => {
		if (pathname) {
			let url = window.origin + pathname
			if (searchParams.toString()) {
				url = `${url}?${searchParams.toString()}`
			}

			posthog.capture('$pageview', {
				$current_url: url,
			})
		}
	}, [
		pathname,
		searchParams,
		posthog,
	])

	const {
		user,
	} = useUser()

	useEffect(() => {
		if (user) {
			posthog.identify(user.id, {
				name: user.fullName,
				email: user.emailAddresses[0].emailAddress,
				environment: publicConfig.posthog.environment || 'development',
			})
		}
	}, [
		user,
	])

	const {
		organization,
	} = useOrganization()

	useEffect(() => {
		if (organization) {
			posthog.group('organization', organization.id, {
				name: organization.name,
				environment: publicConfig.posthog.environment || 'development',
			})
		}
	}, [
		organization,
	])

	return null
}

export default PostHogPageView
