'use client'

import { VercelToolbar } from '@vercel/toolbar/next'
import { useUser } from '@clerk/nextjs'

const StaffToolbar = () => {
	const {
		user,
	} = useUser()

	const isEmployee = user?.emailAddresses.some((email) => {
		return email.emailAddress.endsWith('@gotrestle.com')
	}) || false

	return isEmployee ? <VercelToolbar /> : null
}

export default StaffToolbar
